import React from 'react'

const Contact = () => {
    return (
        <div name='contact' className='w-full h-screen bg-[#0a192f] flex justify-center items-center p-4'>

            <form method='POST' action="https://getform.io/f/bef0e966-a282-442e-ac04-d2bc6df24986" className='flex flex-col max-w-[600px] w-full'>
                <div pb-8>
                    <p className='text-4xl font-bold inline border-b-4 border-[#a32638] text-gray-300'>Contact</p>
                    <p className='text-gray-300 py-4'>Feel free to submit the form below or shoot me an email at: keshav@kunver.com</p>
                </div>
                <input className='bg-[#ccd6f6] p-2' type="text" placeholder='Name' />
                <input className='my-4 p-2 bg-[#ccd6f6]' type="Email" placeholder='Email' name="email" />
                <textarea className='bg-[#ccd6f6] p-2' name="message" rows="6" placeholder='Message'></textarea>
                <button className='text-white border-2 hover:bg-[#a32638] hover:border-[#a32638] px-4 py-3 my-8 mx-auto flex items-center'>Let's Connect</button>
            </form>
        </div >
    )
}

export default Contact